export const environment = {
    production: true,
    firebaseConfig: {
        apiKey: 'AIzaSyBtc78xw926Y2vrhr7yr2ChT4qT9j_M04s',
        authDomain: 'revolutionapp-58dbe.firebaseapp.com',
        databaseURL: 'https://revolutionapp-58dbe.firebaseio.com',
        projectId: 'revolutionapp-58dbe',
        storageBucket: 'revolutionapp-58dbe.appspot.com',
        messagingSenderId: '835660629549',
        appId: '1:835660629549:web:287c3e0810bea5a3791aa4',
        type: 'service_account',
        vapidKey: 'BM0EOjRENd-aiziJcrLUotAfeBFOqpyVZoy4-JOqfADQva4cgcNBk2Oj587w4rS9NMIAYw6BeEruZKP3bOMUMy8',
        project_id: 'revolutionapp-58dbe',
        private_key_id: '7458e2bc6765f64c6d73755cf9e295976f6614f4',
        private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCcawOffRj2IDTg\npjRLOuJ1KJjPSDP+Ia/enYt4HRZgcvIAcumxr3jK9LpHZqLieHDJ6dV0KSevqu1k\n6ztJS6ItR4HYn3R4paaJVYud7006wci7g563+Hnnp+N2Bv4j+rGRmb+VfegF9Y5Z\nKLPfG0jB5dLTE94zBto1mtqCBRc9KQj3IOEs3CQ2RjWPEuuOAGYPnVkI5L0oyLiJ\nLqywEA+PcVktxDA7BCErNRC5WoSxrFEdfIcPAg+UhVGocG2sWdCkt7lSP+FZ2o4F\nKt+8VcBghOPHGM/LywKZYWfjfJU+CpxqfwgfCAxdVWwZ030hAkIKf7RQAPEc2Q3d\nv3BjBMWnAgMBAAECggEAGuqJWZEP0g6xurSvDAEKhgmh3g15MPKADolCvj0XJCqz\nfSNJFpPw78/UHERLpDgAhKZR3mbUlvSNXE/kSFUVB8Cl1e5+fuJWeYx4dYl/2ywM\njEP2dIb0B0qLKDzEz9EsbDcuUh2z5w6d27bW2P4bc45GTJ8/Hb3An/fRQQ16NcJM\nUFZsJMq9l34XZb3qoW9GRLyaDw1WwJxm0blKK5X1yMK8OrsP/9jCbcDMDTiPASbQ\nMrztXV49VhLxkv6Gk2059K8cs86IKLidrWj8GqC/d5MGWHAaY+EygReVM70ar+oz\nx1P010Fq5Cw6V3RcRCYWWYuqew424nwXRByY83yRBQKBgQDaXW5W2/bdouNkxnOk\n+AzGrYxFzhk6WqWkwDc29pXgcEELnKxB8jNFl41+cO85vwAKCz9hq5jfhevvHXPI\nUAehaHQXsBvR5YUDG2OC1hcrxeYpk1VRZDmXBTrvhkMohBg+20eLF/zvnNX/Ng7B\ndle5FpmGvg3ufPwj7R4r1Y/nQwKBgQC3YGWn1SMxWIsTndBmzO7xF3orcngdh68R\n91cknrjoRRjsEh0xJkh9/tbTdzlhrWKYu8tNibzSTHNTLSdmYWBUToCMscO42RIg\nMDDQ03mQf9KiBCD0Lyrxg4325fw6Hbo2Y8lDrq1y0TPJ1LyIiNisQnmdRalII47U\nzTWoHPdHzQKBgG0WZ9z0nmsyyK67hWJQmPFYGc91mpzQVBft/qdyoIUzDwuiBVwc\nhwipIYKdIKWUiYx6+frtIr3nxWn7KLBVarpz2CtNS1icdMqMkDX4hjLT9pumguTa\nUPrlHgAy7rH667HbEe6m2TFLBI0ukEiNul04sRHjOEqAHeZEuuHjGrTJAoGAUYkX\nKizm3QYVsL827r6ob6KUu8TGC7tM6Jwlry6z4PL4Rd84A6aWDQ/GzXfxl7DUajz2\nBJ/9yTQdAxrMrgUWI+ZT2jsgX4yFNrK2weOF5e4v5cIYNPrUCVcJRIK2R9D3/z8X\nOnTswmSqtk9VFRBj5xQXpwpWXrEQzSYBeaKHgPECgYEA2jsBUJVdMaBcSSx/RfWS\nRWrjmt0g3weBDuUUV4fg50ntIIOecL1fxxfHytGKhffNhgt1ikjsoyDTShfK9SIz\nQgeFE5KExjUU/TnLz0AuYmfO3PEUD/rnxR2KE6SQfYIjb7XgPDdkXTvFjGbneylu\nJlXKx6yrmEgd1y01c31VgSM=\n-----END PRIVATE KEY-----\n',
        client_email: 'firebase-adminsdk-o4zb0@revolutionapp-58dbe.iam.gserviceaccount.com',
        client_id: '104491722004320331446',
        auth_uri: 'https://accounts.google.com/o/oauth2/auth',
        token_uri: 'https://oauth2.googleapis.com/token',
        auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
        client_x509_cert_url: 'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-o4zb0%40revolutionapp-58dbe.iam.gserviceaccount.com',
        firebase_Bucket: 'gs://revolutionapp-58dbe.appspot.com'
    },
    zoomConfig: {
        apiKey: 'ni2McdRqS-a_v4R0pXYJZw',
        apiSecret: 'CVWF4lXKzOhmhKj99SqPjRDiJbYqMWwH1OcW',
        meetingNumber: 9751547042,
        userEmail: 'dispatch@revstaff.com',
        passWord: 'RigBox123'
    },
    rigboxConfig: {
        // apiURL: 'https://api.rigbox.com',
        apiURL: 'https://test-api.rigbox.com',
        revolutionStaffingApiHeaders: {
            Authorization: 'Basic cmlnYm94YXBpdXNlcjpiNDUxNzE0NzBjMmY0NDExYjM2MWNlZDM1NWUzZjM0YQ==',//'Basic VEFMdU9QYVI6RE9zeGVHUWVKI2hyb0c3I0w4bFVJY2lLIyFZSEpha0Y=',
            RigBoxId: '1102',
            'Company-Code': 'Rev'
        },
        helpUnlimitedApiHeaders: {
            Authorization: 'Basic cmlnYm94YXBpdXNlcjpiNDUxNzE0NzBjMmY0NDExYjM2MWNlZDM1NWUzZjM0YQ==',//'Basic S0FnZW9kZW46dHU3OTUjTkIjek5EODleczczc3ZONCRWTnlwQ0RMcjk=',
            RigBoxId: '1005',
            'Company-Code': 'HU'
        },
        essentialStaffingApiHeaders: {
            Authorization: 'Basic cmlnYm94YXBpdXNlcjpiNDUxNzE0NzBjMmY0NDExYjM2MWNlZDM1NWUzZjM0YQ==',//'Basic aEFnVW1Qc3k6elZRT0JeI0NSVnVnVG9qSTRpRmZyUSN6IUM0eSFhdjA=',
            RigBoxId: '1102',
            'Company-Code': 'ES'
        },
        sendNewUsersOnly: false
    }
};
